<template>
    <div>
        <TitleAdmin msg="view versus" routerAdmin="Admin" />
        <viewRounds />
    </div>
</template>
<script>
import TitleAdmin from '@/views/constants/titleAdmin.vue'
import viewRounds from '@/components/admin/versus/index.vue'
export default {
    components: {
        TitleAdmin,
        viewRounds
    }
}
</script>